<template >
  <div class="boxCenterList">
    <h3 style="padding-top:20px ;text-align:center"> 隐私保护 </h3>
    <br /> “一县一特交易平台”指深圳市好实再科技有限公司及其关联方（以下简称“我们”或“平台”）合法拥有并运营的、标注名称为“一县一特交易平台”（包括“一县一特交易平台”PC版、IOS版、Android版及小程序版等关联版本）的客户端应用程序、官方网站以及供第三方网站和应用程序使用的软件开发工具包（SDK）和应用程序编程接口（API）。
    <br /> 我们作为“一县一特交易平台”的运营者，深知个人信息对你的重要性，我们将按照法律法规的规定，保护你的个人信息及隐私安全。我们制定本隐私政策并特别提示：希望你在使用“一县一特交易平台”及相关服务前仔细阅读并理解本隐私政策，以便作出适当的选择。
    <br /> 概要
    <br /> 我们将通过本隐私政策向你介绍不同场景下我们如何处理个人信息。当你开启或使用“一县一特交易平台”时，为实现你选择使用的功能、服务，或为遵守法律法规的要求，我们会处理相关信息。除实现“一县一特交易平台”基本功能、服务所需的信息，和根据法律法规要求所必需的信息之外，你可以拒绝我们处理其他信息，但这可能导致我们无法提供对应功能、服务。我们将在隐私政策中逐项说明相关情况，有关你个人信息权益的重要条款已用加粗形式提示，请特别关注。
    <br /> “一县一特交易平台”及“一县一特交易平台”各关联版本之间的实际功能可能略有差异，但如关联版本无独立隐私政策，我们会遵循本隐私政策收集、处理你的个人信息。如果“一县一特交易平台”的关联版本有独立隐私政策的，则关联版本的隐私政策同时适用。本隐私政策以及该关联版本隐私政策二者不一致的，适用关联版本的隐私政策。
    <br /> 除本隐私政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向你说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本隐私政策的一部分，并与本隐私政策具有同等效力。
    <br /> 下文将帮你详细了解我们如何收集、使用、存储、传输、公开与保护个人信息；帮你了解查询、更正、补充、删除、复制、转移个人信息的方式。其中，有关你个人信息权益的重要内容已用加粗形式提示，请特别关注。
    <br /> 1.我们如何收集和使用个人信息
    <br /> 2.数据使用过程中涉及的合作方以及转移、公开个人信息
    <br /> 3.管理你的个人信息
    <br /> 4.我们如何保护个人信息的安全
    <br /> 5.我们如何存储个人信息
    <br /> 6.我们如何保护未成年人
    <br /> 7.隐私政策的查阅和修订
    <br /> 8.联系我们
    <br /> 1 我们如何收集和使用个人信息
    <br /> 1.1 帐号服务
    <br /> 1.1.1 注册、登录
    <br /> 当你注册、登录“一县一特交易平台”及使用相关服务时，你可以通过手机号码创建帐号，并且你可以完善相关的网络身份识别信息（“一县一特交易平台”号、头像、昵称、密码），我们收集这些信息是为了帮助你完成注册。收集手机号码是履行国家法律法规关于网络实名制（真实身份信息认证）要求的必要信息，如果你拒绝提供手机号码用于注册、登录，我们可能无法为你提供信息发布功能。
    <br /> 基于我们与通信运营商的合作，当你使用“一键登录”功能时，经过你的同意，我们会使用运营商提供的功能，将你设备中的手机号码作为你的注册、登录帐号并免于密码或动态验证码核验，为你提供快捷的注册、登录服务。如果你不希望使用“一键登录”功能，可以通过其他方式注册、登录“一县一特交易平台”。
    <br /> 你可以使用第三方帐号注册、登录“一县一特交易平台”，但需要授权我们获取你在第三方平台的信息（头像、昵称等公开信息以及你授权的其他信息），用于生成与该第三方帐号绑定的“一县一特交易平台”帐号，使你可以直接注册、登录并使用“一县一特交易平台”和相关服务。部分情况下，我们需要重新验证并绑定你的手机号码，以确认该第三方帐号的真实性和关联性。
    <br /> 如果你忘记了你的“一县一特交易平台”的登录方式，可以使用找回密码功能。为保障你帐号的安全，我们可能需要你填写相关信息以进行验证，必要时可能通过真实身份信息验证等方式验证你的身份。
    <br /> 1.1.2 实名认证
    <br /> 在你开通直播、申请提现或使用其他需实名认证的功能或服务时，为符合法律法规相关要求，保障你或其他用户的安全，或维护功能/服务的安全稳定，需要你提供真实身份信息（真实姓名、身份证件号码或人脸信息，根据实际情况可能有所不同）以完成实名认证。
    <br /> 在实名认证的过程中，可能需要由提供认证服务的第三方认证机构核验你的真实身份信息。这些信息仅供完成实名认证的目的，或其他法律法规所规定的用途，未经你明示授权不会用作其他目的。你可以拒绝提供，但将可能无法使用必须实名认证才可使用的功能或相关服务。不能使用此类功能或服务，不影响你使用其他功能与服务。
    <br /> 基于法律法规的相关要求，你的实名认证信息还可能被我们用于青少年/儿童保护、防沉迷相关的产品或服务。
    <br /> 1.1.3 帐号公开信息
    <br /> 我们会在你注册“一县一特交易平台”时为你的帐号生成“一县一特交易平台”号，“一县一特交易平台”号是你的帐号的公开识别符号。你可以根据自身需求选择设置“一县一特交易平台”号（自主设置的次数有限制）。
    <br /> 你可以自主填写性别、生日、地区及个人介绍来完善你的信息。不填写信息不会影响你使用“一县一特交易平台”帐号及服务。
    <br /> 我们发布违规处罚公告时，会公布违规帐号的商品描述、价格等信息。
    <br /> 1.1.4 “一县一特交易平台”统一帐号和关联版本 <br /> 如无特别说明，“一县一特交易平台”的各关联版本均使用统一的“一县一特交易平台”帐号服务，关联版本包括但不限于“一县一特交易平台”PC版、IOS版、Android版及小程序版等关联版本，以及我们推出的其他“一县一特交易平台”版本。
    <br /> 你可以使用“一县一特交易平台”帐号便捷地登录、使用“一县一特交易平台”关联版本，以使用对应版本的服务或功能。为了在“一县一特交易平台”的各关联版本中提供一致的服务体验，我们使用SDK或相关技术同步“一县一特交易平台”和关联版本中的帐号和内容，其中可能包括：共享昵称、头像、地区、性别等你设置为公开的帐号信息；同步关注、商品等用户关系；同步你使用此帐号发布的内容；届时，你可根据实际需要，单独授权，从而同步你授权的其他信息。
    <br /> 1.2 内容浏览和搜索
    <br /> 在你浏览“一县一特交易平台”内容的过程中，我们会记录你的使用情况。如果你未登录帐号，我们会通过设备对应的标识符信息来记录你的【点击、浏览、分享信息】。如果你已登录帐号，我们会通过你的“一县一特交易平台”帐号记录你的【点击、浏览、分享、关注、收藏、反馈、发布等】。
    <br /> 在为你提供同城频道内容或者与设备所在位置相关的内容或服务时，经过你的授权，我们会使用与本服务相关的地理位置信息（如精确定位信息、WLAN接入点、蓝牙和基站），以保障内容或服务的质量，并向你推荐可能感兴趣的内容或服务。其中精确定位信息是个人敏感信息，若你拒绝授权位置权限会影响定位的准确性，但不会影响其他功能与服务的正常使用，我们也仅会基于网络信息（如IP）对应的城市及/或区县向你展示可能相关的内容或服务。
    <br /> 当你使用搜索时，我们会收集你的搜索关键字信息、日志记录。搜索关键字信息会暂时存储在你的设备中，因此当你在同一设备上再次使用“一县一特交易平台”搜索功能时，我们会向你展示你的搜索历史记录。
    <br /> 1.3 信息发布
    <br /> 1.3.1 商品信息、图文
    <br /> 你发布商品信息图片、文字时，我们会根据你使用的功能类型分别请求你授权相机、相册、麦克风权限。 如你拒绝授权，将无法使用发布功能，但不影响你使用“一县一特交易平台”的其他功能。
    <br /> 1.3.2 工具 <br /> 你使用“一县一特交易平台”中提供的工具发布内容时，部分工具需要处理你的个人信息：
    <br /> （1） 如你发布内容时选择使用平台的工具，我们需要识别你的面部特征值信息，以提供更好的视觉效果。在此过程中，除非另有明确说明并征得你的单独同意，我们不会回传、存储或向第三方提供上述信息，此信息将仅在你的设备（例如手机）本地处理。如你拒绝授权，将无法使用本功能，但不影响你使用“一县一特交易平台”的其他功能。
    <br /> （2） 如你在发布时添加第三方开发者提供的小程序、第三方开发者将收集、处理为实现功能所需的信息。除非另有说明，我们不直接处理该第三方开发者收集的你的个人信息。
    <br /> 1.4 个性化推荐
    <br /> 功能说明：为了向你展示、推荐相关性更高的信息，提供更契合你要求的服务，“一县一特交易平台”会收集、使用你的个人信息并通过计算机算法模型自动计算、预测你的偏好，以匹配你可能感兴趣的信息或服务，以下我们将向你详细说明该个性化推荐服务的运行机制以及你实现控制的方式。
    <br /> a) 我们提供个性化推荐服务的范围包括但不限于展示图文/商品信息内容，提供搜索结果，推荐商品、服务或广告。
    <br /> b) 为了预测你的偏好，我们可能会收集、使用你的设备信息、位置信息以及在使用“一县一特交易平台”时的行为信息，行为信息包括你在使用“一县一特交易平台”时的内容浏览、信息发布、搜索、点击、关注、收藏、浏览、分享及交易等活动的对应信息。我们会对上述信息进行自动分析和计算，机器（或系统）会根据计算结果自动筛选并推送你可能更感兴趣的内容。
    <br /> c) 我们会根据你在使用“一县一特交易平台”过程中的内容浏览相关行为对推荐模型进行实时反馈，不断调整优化推荐结果，更好地向你提供优质商品内容。
    <br /> 请知悉，“一县一特交易平台”中的个性化推荐算法并不会识别特定自然人的真实身份，仅是基于用户行为和特征提供相关性更高与更丰富的信息。
    <br /> 你可根据本隐私政策关闭个性化的内容推送，或关闭个性化或程序化广告推送，当你关闭后，我们不会基于个性化推送的目的处理你的个人信息，而仅会向你推送与你的偏好无关的具有普遍性的内容或广告。
    <br /> 1.5 其他功能
    <br /> 1.5.1 消息通知
    <br /> 你知悉并同意，我们在运营中可能会通过你在使用产品及/或服务的过程中所提供的联系方式（例如：电话号码），向你同时发送一种或多种类型的通知，例如消息告知、身份验证、安全验证、用户使用体验调研；此外，我们也可能会以短信、电话的方式，为你提供你可能感兴趣的服务、功能或活动等商业性信息。但请你放心，如你不愿接受这些信息，你可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
    <br /> 请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
    <br /> 1.5.2 分享互动
    <br /> 在你分享或接收被分享的信息、参加活动等情形下，“一县一特交易平台”客户端需要在本地访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。“一县一特交易平台”客户端仅在本地识别出剪切板内容属于“一县一特交易平台”跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，“一县一特交易平台”客户端不会上传你剪切板的其他信息至我们的服务器。另外，我们可能需要读取你的手机相册以便于你分享或接收被分享的视频和图片。
    <br /> 有些手机设备的系统会提示应用（App）正在读取剪切板或相册，但该系统提示无法区分是本地处理还是上传，且由于统计方式的差异，不排除误报的可能。因此，如果你发现类似提示并有疑问，请与我们联系，以便我们定位问题。
    <br /> 1.5.3 订单中心
    <br /> 当你购买商品或服务时，我们或提供服务的第三方会收集你的订单信息。同时，如你开启订单同步功能，我们会汇总展示上述第三方收集的你的订单信息，以方便你在【我的】-【我的订单】中查询全部订单。
    <br /> 1.6 运营与安全运行
    <br /> 我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护相关产品或服务的正常稳定运行，保护你或其他用户或公众的安全及合法利益，我们会收集如下必要信息：
    <br /> （1）为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。
    <br /> （2）为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
    <br /> （3）我们可能使用你的帐号信息、设备信息、服务日志信息，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断帐号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
    <br /> 1.7 Cookie等同类技术的使用
    <br /> Cookie 和设备信息标识等同类技术是互联网中普遍使用的技术。当你使用“一县一特交易平台”及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个Cookie 或匿名标识符，以收集、标识你访问、使用本产品时的信息。我们承诺，不会将Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie 和同类技术主要为了实现以下功能或服务：
    <br /> 保障产品与服务的安全、高效运转：我们可能会设置认证与保障安全性的Cookie 或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
    <br /> 帮助你获得更轻松的访问体验：使用此类技术可以帮助你省去重复填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。
    <br /> 为你推荐、展示、推送你可能感兴趣的内容或帐号：我们可能会利用此类技术了解你的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息或功能，并优化你对广告或内容的选择。
    <br /> 在“一县一特交易平台”的分享页中，我们可能会使用Cookie对浏览活动进行记录，用于向你推荐信息和排查崩溃、延迟的相关异常情况以及探索更好的服务方式。
    <br /> Cookie的清除 ：如你使用“一县一特交易平台”网页版，大多数浏览器均为用户提供了清除浏览器缓存数据的功能，你可以在浏览器设置中操作清除Cookie数据，但清除后可能无法使用由我们提供的、依赖于Cookie的功能或服务。
    <br /> 1.9 服务内容的变更
    <br /> 请你了解，随着我们业务的发展，可能会调整“一县一特交易平台”提供的产品或服务。如调整变化后的产品或服务所处理个人信息的目的、方式和范围发生变化的，我们会再次向你告知，并根据法律法规的相关要求征得你的同意。
    <br /> 1.10 依法豁免征得同意处理个人信息
    <br /> 请你理解，在下列情形中，根据法律法规，我们处理你的个人信息无需征得你的授权同意。
    <br /> a) 根据你的要求订立或履行合同所必需；
    <br /> b) 为履行法定职责或者法定义务所必需，例如与国家安全、国防安全、与刑事侦查、起诉、审判和判决执行等直接相关的法定职责或者法定义务；
    <br /> c) 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    <br /> d) 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    <br /> e) 在合理的范围内处理你自行公开的个人信息，或者其他已经合法公开的个人信息（如合法的新闻报道、政府信息公开等渠道合法公开的个人信息）；
    <br /> f) 法律法规规定的其他情形。
    <br /> 特别说明：根据法律规定，如信息无法单独或结合其他信息识别到特定个人的，其不属于个人信息。当你的信息可以单独或结合其他信息识别到你时，或我们把没有与任何特定个人建立联系的数据与你的个人信息结合使用时，我们会将其作为你的个人信息，按照本隐私政策进行处理与保护。
    <br /> 2 数据使用过程中涉及的合作方以及转移、公开个人信息
    <br /> 2.1 数据使用过程中涉及的合作方
    <br /> 2.1.1基本原则
    <br /> 我们与合作方合作过程中，将遵守以下原则：
    <br /> a) 合法原则：涉及数据处理活动的，应当遵守合法原则。
    <br /> b) 正当与最小必要原则：数据使用必须具有正当目的，且应以达成目的必要为限。
    <br /> c) 安全审慎原则： 我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。
    <br /> 2.1.2 委托处理
    <br /> 对于委托处理个人信息的场景，我们会与受托合作方根据法律规定签署相关处理协议，并对其个人信息使用活动进行监督。 <br /> 2.1.3共同处理
    <br /> 对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。
    <br /> 2.1.4 合作方的范围
    <br /> 若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。
    <br /> 2.2 合作的场景
    <br /> 2.2.1 为实现“一县一特交易平台”的业务功能
    <br /> a) 地理位置服务：当你使用地理位置相关服务时，位置服务提供商（如高德地图）可能会通过SDK或相关技术使用你的GPS信息、设备信息以便可以向你返回位置结果。拒绝上述使用仅会影响地理位置服务功能，但不影响其他功能的正常使用。
    <br /> b) 消息通知：为与你使用的终端机型适配消息推送功能，终端设备制造商（华为、小米、OPPO、VIVO等）可能通过SDK等技术使用你的手机型号、版本及相关设备信息。
    <br /> c) 登录、绑定其他第三方帐号：当你使用“一县一特交易平台”帐号登录其他第三方的产品或服务时，或将“一县一特交易平台”帐号与其他第三方帐号绑定时，经过你的同意，上述第三方的产品或服务将会使用你的昵称、头像及其他你授权的信息。
    <br /> d) 购买商品或服务：“一县一特交易平台”中的购物功能由与我们合作的电商平台向你提供。电商平台可能需要收集你浏览商品、购买商品的相关信息。如需进一步了解，你可以查阅合作方的对应隐私政策。
    <br /> e) 小程序：当你在“一县一特交易平台”中使用由第三方提供的小程序等应用时，如小程序需使用你的个人信息，会申请你的授权同意。未经你同意，我们不会向这些开发者（或运营者）提供你的个人信息。部分小程序可能会向你申请使用地理位置、麦克风、摄像头等系统权限，你可以在小程序中撤回授权。
    <br /> f) 活动参与：当你选择参加我们举办的营销活动时，经过你的同意，我们与合作方可能需要使用你的姓名、通信地址、联系方式、银行账号等信息，以便合作方向你兑现活动奖励。当你参与我们和关联方共同举办的营销活动时，可能会在多个参与活动的移动应用程序（简称“活动平台”）之间同步你参与活动的帐号（含标识符）、参与成绩、进度、收益等信息，以保障你在活动中获得完整的服务体验。
    <br /> g) 客户服务：为及时处理你的投诉、建议以及其他诉求，客户服务提供商需要使用你的帐号及相关信息，以及时了解、处理和响应你的需求。如有必要，也可能需要使用你的手机号码或通过其他通讯方式与你取得联系。
    <br /> h) 支付服务：支付功能由与我们合作的第三方支付机构向你提供服务。第三方支付机构可能需要收集你的姓名、身份证号、银行卡类型及卡号、有效期及手机号码。身份证号和银行卡相关信息是个人敏感信息，这些信息是支付功能所必需的信息，拒绝提供将导致你无法使用该功能，但不影响其他功能的正常使用。“一县一特交易平台”支付功能由第三方支付机构向你提供服务。当你使用“一县一特交易平台”支付时，第三方支付机构会根据法律法规及监管规定收集你的相关信息以为你提供支付服务。如需进一步了解“一县一特交易平台”支付功能的隐私政策，你可以进一步查阅合众易宝的相关隐私政策。
    <br /> 2.2.2 广告 <br /> 我们可能与广告的服务商、供应商和其他合作伙伴（合称“广告合作方”）共享分析去标识化的设备信息或统计信息，这些信息难以或无法与你的真实身份相关联。这些信息将帮助我们分析、衡量广告和相关服务的有效性。
    <br /> a) 广告推送与投放：进行推送/推广和广告投放或提供相关服务的广告合作方可能需要使用去标识化或匿名化后的设备、网络、渠道信息以及标签信息；与广告主合作的广告推送/推广、投放/分析服务的广告合作方可能需要使用前述信息，以实现广告投放、优化与提升广告有效触达率。
    <br /> b) 广告统计分析：提供广告统计分析服务的广告合作方可能需要使用你的设备、网络、广告点击、浏览、展示以及广告转化数据等信息用于分析、衡量、提升广告和相关服务的有效性。
    <br /> c) 广告合作方对信息的使用：广告合作方可能将上述信息与其合法获取的其他数据相结合，以优化广告投放效果，我们会要求其对信息的使用遵循合法、正当、必要原则，保障用户合法权利不受侵犯。
    <br /> d) 广告留资信息：你在广告中主动填写、提交的联系方式、地址等相关信息，可能被广告主或其委托的合作方收集并使用。
    <br /> 2.2.3 实现安全与统计分析
    <br /> a) 保障使用安全：我们非常重视产品和服务的安全性，为保障“一县一特交易平台”用户的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、帐号及日志信息。
    <br /> b）分析产品情况：为分析“一县一特交易平台”的产品和服务的稳定性，提供分析服务的合作方可能需要使用服务情况（崩溃、闪退记录）、设备标识信息、应用总体安装使用情况等信息。
    <br /> c) 学术科研：为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与相关机构使用去标识化或匿名化的数据。
    <br /> 2.3 运营主体变更
    <br /> 随着业务的持续发展，我们将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护你的个人信息，继受方变更原先的处理目的、处理方式的，我们将要求继受方重新征得你的授权同意。
    <br /> 2.4 停止运营
    <br /> 如果我们停止运营产品或服务，将及时停止继续收集你的个人信息。我们会逐一送达通知或以公告的形式向你发送停止运营的告知，并对我们所持有的与已关停的产品或服务相关的个人信息进行删除或匿名化处理。
    <br /> 2.5 公开
    <br /> 我们不会主动公开你未自行公开的信息，除非遵循国家法律法规规定或者获得你的同意。
    <br /> 2.6 依法豁免征得你的授权同意的情形
    <br /> 请你理解，在下列情形中，根据法律法规及国家标准，合作方使用，或我们转移、公开你的个人信息无需征得你的授权同意：
    <br /> a) 根据你的要求订立或履行合同所必需；
    <br /> b) 为履行法定职责或者法定义务所必需，例如与国家安全、国防安全、与刑事侦查、起诉、审判和判决执行等直接相关的法定职责或者法定义务；
    <br /> c) 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    <br /> d) 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    <br /> e) 在合理的范围内处理你自行公开的个人信息，或者其他已经合法公开的个人信息（如合法的新闻报道、政府信息公开等渠道合法公开的个人信息）；
    <br /> f) 法律法规规定的其他情形。
    <br /> 3 管理你的个人信息
    <br /> 我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销帐号、投诉举报等权利，以使你有能力了解并保障你的个人信息安全。
    <br /> 但请你理解，特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响我们此前基于你的授权而开展的个人信息处理。
    <br /> 我们在不同的版本或操作系统中提供的管理方式的路径可能有差异，请以实际为准。
    <br /> 3.1 设置管理
    <br /> 3.1.1 基本功能模式
    <br /> 搜索和商品展示及交易是“一县一特交易平台”的基本功能，因此如果你仅允许我们处理实现基本功能所必须的个人信息，可以选择开启基本功能模式。
    <br /> 在此模式下，我们仅会基于提供基本功能和保护系统安全的需要处理你的个人信息。
    <br /> 3.1.2 系统权限设置
    <br /> a) 你可以在设备的设置功能中开启或关闭地理位置、通讯录、摄像头、麦克风、相册等权限，改变授权范围或撤回你的授权。撤回授权后我们将不再收集与这些权限相关的信息，但不会影响我们此前基于你的授权而开展的个人信息处理。
    <br /> b) 你可以在设备的设置中选择是否接收“一县一特交易平台”发送的系统通知，或设置你希望接收的系统通知的形态。同时，你也可以在【我的】-【设置】-【通知设置】中选择是否接收“一县一特交易平台”发送的应用内的功能通知，或设置你希望接收的应用内的功能通知的范围。
    <br /> 3.1.3 帐号管理
    <br /> 你可以在【我的】-【设置】-【帐号与安全】中管理你的“一县一特交易平台”帐号或申请相关服务：
    <br /> 【保存登录信息】中可以选择是否需要我们记录你的历史登录信息。
    <br /> 【授权管理】中可以管理你对其他第三方产品或服务的授权。请你注意，在你取消对其他第三方产品或服务的授权后，你无法使用“一县一特交易平台”帐号再次登录该第三方产品或服务。
    <br /> 【第三方帐号绑定】中可以管理你的“一县一特交易平台”帐号中绑定的第三方帐号。
    <br /> 【实名认证】中申请帐号实名认证。
    <br /> 3.1.4您可以通过以下方式访问及管理您的信息：
    <br /> a) 查询、更正和补充您的信息
    <br /> 您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：
    <br /> 登录一县一特交易平台”网站，进入“我的”首页，点击账户管理后查询、更正、删除个人资料及个人账户相关信息。
    <br /> 登录一县一特交易平台”并选择联系客服寻求帮助，协助您查询、更正或补充您的信息。
    <br /> b) 删除您的信息
    <br /> 在以下情形中，您可以向我们提出删除个人信息的请求：
    <br /> 如果我们处理个人信息的行为违反法律法规。
    <br /> 如果我们处理个人信息的行为严重违反了与您的约定。
    <br /> 如果您不再使用我们的产品或服务，或您主动注销了账号。
    <br /> 若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
    <br /> 当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，但我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
    <br /> c) 响应您的上述请求
    <br /> 为保障个人信息安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    <br /> 对于您的合理请求，我们原则上不收取费用，但对于多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要开发新系统等过多技术手段、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
    <br /> 按照法律法规要求，在以下情形中我们将无法响应您的请求：
    <br /> 与国家安全、国防安全有关的。
    <br /> 与公共安全、公共卫生、重大公共利益有关的。
    <br /> 与犯罪侦查、起诉、审判和执行判决等有关的。
    <br /> 有充分证据表明个人信息主体存在主观恶意或滥用权利的。
    <br /> 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    <br /> 涉及商业秘密的。
    <br /> 其他违反法律法规要求的。
    <br /> 4 我们如何保护个人信息的安全
    <br /> 我们非常重视你的个人信息安全，将努力采取合理的安全措施来保护你的个人信息，确保我们的个人信息处理活动符合法律、行政法规或依法可适用的其他制度的要求，并防止未经授权的访问以及个人信息泄露、篡改、丢失。
    <br /> 4.1 安全技术措施
    <br /> 我们会使用不低于行业通常水平的加密、去标识化技术、匿名化处理及相关合理可行的手段保护你的个人信息，并使用安全保护机制防止你的个人信息遭到恶意攻击。
    <br /> 4.2 管理制度
    <br /> 我们会建立专门的安全部门、安全管理制度、数据安全流程保障你的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息。我们会定期对人员进行安全教育和培训，并适时对数据和技术进行安全审计。
    <br /> 我们会制定并组织实施个人信息安全事件应急预案，并在发生个人信息安全事件时立即启动应急预案，努力控制这些安全事件的影响，防止不利后果扩大。一旦发生或可能发生个人信息安全事件（泄露、篡改、丢失）的，我们将按照法律法规的要求，及时向你告知以下情况：发生或者可能发生泄露、篡改、丢失的个人信息种类、原因和可能造成的危害，我们已采取的补救措施，你可以采取的减轻危害的措施以及与我们联系的方式。
    <br /> 我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。
    <br /> 同时，我们还将按照相关监管部门要求，上报个人信息安全事件的处置情况。
    <br /> 4.3 安全认证
    <br /> 目前，“一县一特交易平台”已经通过了国家信息安全等级保护（三级）的测评和备案，并通过了国际权威的ISO27001信息安全认证及ISO27701隐私信息管理认证。
    <br /> 4.4 安全提示
    <br /> 尽管已经采取了上述合理有效措施，并已经遵守了相关法律法规要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。
    <br /> 你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。
    <br /> 你一旦离开“一县一特交易平台”，浏览或使用第三方提供的其他产品或服务，我们将没有能力和直接义务保护你向第三方提交的任何个人信息，无论你登录、浏览或使用上述产品或服务是否基于“一县一特交易平台”的链接或引导。但我们会提示你注意将进入第三方产品或服务，以便你更好地保护你的个人信息。
    <br /> 5 我们如何存储个人信息
    <br /> 5.1 存储地点
    <br /> 我们依照法律法规的规定，将在中华人民共和国境内运营过程中收集和产生的你的个人信息存储于境内。我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求你的同意。
    <br /> 5.2 存储期限
    <br /> a) 我们仅会在为你提供“一县一特交易平台”产品或服务所必需的期间内保留你的个人信息，例如： <br /> 手机号码：当你使用手机号码注册登录后，我们需要持续保留你的手机号码，以便于向你提供正常的服务、应对你可能的查询和客诉并保障你的帐号和系统安全。 <br /> 你发布的内容及相关信息，在你未撤回、删除或未注销帐号之前，我们会予以保留用于向你提供持续的业务功能。
    <br /> b) 如果你注销帐号、主动删除个人信息或信息超出必要的保存期限后，我们将对你的个人信息进行删除或匿名化处理，但以下情况除外： <br /> 遵从法律法规有关信息留存的要求（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。 <br /> 出于财务、审计、争议解决等目的需要合理延长期限的。
    <br /> 6 我们如何保护未成年人
    <br /> 6.1 未成年人通用条款
    <br /> a) 若你是未满18周岁的未成年人，在使用“一县一特交易平台”及相关服务前，应在你的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
    <br /> b) 若你是未满14周岁的未成年人的监护人，在使用“一县一特交易平台”及相关服务前，应为你的被监护人阅读并同意本隐私政策。
    <br /> c) 我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息。如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。同时我们建立了严格的未成年人信息收集使用规则，以保护儿童（未满14周岁）和青少年（已满14周岁但未满18周岁）的个人信息安全，你可以通过阅读《儿童/青少年使用须知》了解更多信息。
    <br /> d) 若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
    <br /> 7 隐私政策的查阅和修订
    <br /> 7.1 查阅
    <br /> a）你可以在注册登录页面，或者登录帐号后在【我的】-【设置】- 【隐私政策】页面查看本隐私政策。
    <br /> b）请你了解，为了满足运营需要，我们可能会根据你所使用的手机型号、系统版本、移动客户端等因素开发不同的版本，因此在你使用特定版本时，“一县一特交易平台”的功能可能与本隐私政策描述的有所区别，但不会影响数据处理的目的、方式和范围，具体可用功能以你实际使用的情况为准。
    <br /> 7.2 更新及通知
    <br /> a）为了给你提供更好的服务，“一县一特交易平台”产品和服务将不时更新与变化，我们会适时对本隐私政策进行修订。未经你明确同意，我们不会削减你依据当前生效的隐私政策所应享受的权利。
    <br /> b）本隐私政策更新后，我们会在“一县一特交易平台”发出更新版本，并通过站内信或其他适当的方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。
    <br /> c）我们也可能通过功能更新，帮助你更好地管理你的个人信息，请你留意相关的功能说明。
    <br /> 8 如何联系我们
    <br /> 您可以通过以下方式与我们联系，我们将在15天内答复您的请求：
    <br /> 8.1 如对本政策内容、儿童个人信息有任何疑问、意见或建议，您可通过“一县一特交易平台”网站（https://www.hsz88.com/）/App上提供的在线联系方式/客服系统与我们联系。
    <br /> 8.2 我们还设立了个人信息保护团队，您可以通过【info@hsz88.cn】与我们联系或者可以直接寄信到如下地址：深圳市南山区桃源街道平山一路新视艺创客公园B栋6楼法务部（收），邮编：518000。
    <br /> 8.3 本隐私政策作为您与我们签署的《“一县一特交易平台”规则》及其他相关的平台规则不可分割的一部分，均具同等法律效力。
  </div>
</template>