<template >
  <div class="boxCenterList">
    <h3 style="padding-top:20px ;text-align:center">
      联系我们
    </h3>
    <p style="white-space: normal; text-transform: none; word-spacing: 0px; color: rgb(67,67,67); font: 12px/30px Simsun; letter-spacing: normal; text-indent: 0px; -webkit-text-stroke-width: 0px"
       class="MsoNormal"><span style="font-size: 12pt; font-family: 宋体; color: rgb(102,102,102); background-color: white">全国咨询热线：</span><strong><span lang="EN-US"
              style="font-size: 12pt; font-family: Arial; color: red; background-color: white">400-850-1151</span></strong><span lang="EN-US"
            style="font-size: 12pt; font-family: Arial; color: rgb(102,102,102)"> &nbsp; &nbsp;&nbsp;<span style="font-size: small;"> (服务时间： 9:00-18:00）</span><br>
      </span><span style="font-size: 12pt; font-family: 宋体; color: rgb(102,102,102); background-color: white">电话：</span><span lang="EN-US"
            style="font-size: 12pt; font-family: Arial; color: rgb(102,102,102); background-color: white">0755-29120761</span><span lang="EN-US"
            style="font-size: 12pt; font-family: Arial; color: rgb(102,102,102)"><br>
      </span><span style="font-size: 12pt; font-family: 宋体; color: rgb(102,102,102)">邮箱：</span><span lang="EN-US"
            style="font-size: 12pt; font-family: Arial; color: rgb(102,102,102)"><a style="font-size: 15px; color: rgb(67,67,67); text-decoration: none"
           href="mailto:info@hsz88.cn">hsz88@hsz88.com</a></span></p>
    <p style="white-space: normal; text-transform: none; word-spacing: 0px; color: rgb(67,67,67); font: 12px/30px Simsun; letter-spacing: normal; text-indent: 0px; -webkit-text-stroke-width: 0px"
       class="MsoNormal"><span lang="EN-US"
            style="font-size: 12pt; font-family: Arial; color: rgb(102,102,102)">
        <o:p></o:p>
      </span></p>
    <p style="white-space: normal; text-transform: none; word-spacing: 0px; color: rgb(67,67,67); font: 12px/30px Simsun; letter-spacing: normal; text-indent: 0px; -webkit-text-stroke-width: 0px"
       class="MsoNormal"><span style="font-size: 12pt; font-family: 宋体; color: rgb(102,102,102)">深圳总部：</span><span style="font-size: medium;"><span style="font-family: Arial, Verdana, sans-serif;">深圳市南山区桃源街道新视艺创客公园B座6层612</span></span></p>
    <p style="white-space: normal; text-transform: none; word-spacing: 0px; color: rgb(67,67,67); font: 12px/30px Simsun; letter-spacing: normal; text-indent: 0px; -webkit-text-stroke-width: 0px"
       class="MsoNormal"><span style="font-size: 12pt; font-family: 宋体; color: rgb(102, 102, 102); ">惠州分公司：</span><span style="font-size: medium;"><span style="font-family: Arial, Verdana, sans-serif;">惠州市大亚湾经济开发区石化大道西</span><span lang="EN-US"
              style="font-family: Arial; color: rgb(102, 102, 102);">262</span><span style="font-family: 宋体; color: rgb(102, 102, 102);">号</span></span></p>
    <p style="white-space: normal; text-transform: none; word-spacing: 0px; color: rgb(67,67,67); font: 12px/30px Simsun; letter-spacing: normal; text-indent: 0px; -webkit-text-stroke-width: 0px"
       class="MsoNormal"><span lang="EN-US"
            style="font-size: 12pt">
        <o:p></o:p>
      </span>&nbsp;</p>
  </div>
</template>