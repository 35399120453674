<template>
  <div class="boxCenterList">
    <h3 style="padding-top:20px ;text-align:center">
      购物指南
    </h3>
    <p style="padding-top:20px;text-align: center;"><img src="http://img01.hsz88.com.cn/ImageExt/t9/283b7a66d0b2409682d3bbfabad22ade.jpg!!src"
           width="1000"
           height="2690"
           alt=""></p>
  </div>
</template>

