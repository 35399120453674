<template>
  <div class="container">
    <div class="content">
      <!-- 关于我们 -->
      <Type1 v-if="type == 1" />
      <!-- 联系我们 -->
      <Type2 v-if="type == 2" />

      <!-- 隐私保护 -->
      <Type3 v-if="type == 3" />

      <!-- 免责 -->
      <Type4 v-if="type == 4" />
      <!-- 免责 -->
      <Type5 v-if="type == 5" />

    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Type1 from './components/type1.vue'
import Type2 from './components/type2.vue'
import Type3 from './components/type3.vue'
import Type4 from './components/type4.vue'
import Type5 from './components/type5.vue'
export default {
  components: {
    Type1,
    Type2,
    Type3,
    Type4,
    Type5,
  },
  setup () {
    const route = useRoute()
    const type = ref(1) //文本类型
    watch(() => route.params, (nval, oval) => {
      // console.log(nval, oval)
      type.value = nval.id
    }, {
      immediate: true
    })
    return {
      type
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  // min-height: 800px;
  padding-bottom: 50px;
}
</style>