<template >
  <div class="boxCenterList">
    <h3 style="padding-top:20px ;text-align:center">
      关于我们
    </h3>
    <p class="MsoNormal"><span lang="EN-US">&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
    <p class="MsoNormal"><span style="font-family: 宋体; font-size: 9pt; background-image: initial; background-attachment: initial; background-size: initial; background-origin: initial; background-clip: initial; background-position: initial; background-repeat: initial;">&nbsp; &nbsp;深圳市好实再科技有限公司</span><span style="font-size: 9pt; font-family: 宋体;">是成立于</span><span style="font-size: 9pt; font-family: 宋体;">2008年，国家公派留英博士张天文先生，基于国内外零售业及互联网的发展状况，结合未来的发展趋势，研发同成大系统，形成erp、物流、售后服务、支付、前台网上购物一体化系统，</span></p>
    <p class="MsoNormal"
       style="text-indent:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class="MsoNormal"
       style="text-indent:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">2009年整合10大品牌进入试运营阶段。期间，首创全国第一家O2O线下体验店，在四川内江市落成。通过几年的努力，已在四川、湖南、重庆等地建成2000多个实体O2O店和全国售后体系，在深圳、惠州大亚湾及成都建成14万平米的物流及运营基地。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class="MsoNormal"
       style="text-indent:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
    <p class="MsoNormal"
       style="text-indent:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">2014年9月，好实再同成大系统平台向全球所有品牌及消费者开放，让消费者和厂家参与经营和毛利分红。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:9.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class="MsoNormal"
       style="margin: 0pt; text-indent: 21pt; padding: 0pt;"><span style="font-family: 宋体; font-size: 9pt; background-image: initial; background-attachment: initial; background-size: initial; background-origin: initial; background-clip: initial; background-position: initial; background-repeat: initial;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class="MsoNormal"
       style="margin: 0pt; text-indent: 21pt; padding: 0pt;"><span style="font-family: 宋体; font-size: 9pt; background-image: initial; background-attachment: initial; background-size: initial; background-origin: initial; background-clip: initial; background-position: initial; background-repeat: initial;">深圳市好实再科技有限公司主要提供丰富优质的商品，品类包括：手机、电脑及数码周边产品、家电、汽车配件、服装与鞋类、手表、家居与家庭用品、护理用品、地方特产、食品、母婴用品与玩具等。</span><span style="font-family: 宋体; font-size: 9pt; background-image: initial; background-attachment: initial; background-size: initial; background-origin: initial; background-clip: initial; background-position: initial; background-repeat: initial;">
        <o:p></o:p>
      </span></p>
  </div>
</template>